<template>
  <div class>
    <div class="d-flex align-items-center">
      <div class="spinner-grow mr-3" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <h2 class="m-0">Chargement...</h2>
    </div>
  </div>
</template>
<script>
export default {
  name: "LoaderBloc",
  computed: {
    bgClass: function() {
      if (!this.$store.getters["colors/darkMode"]) {
        return "";
      } else {
        return "bg-dark text-white";
      }
    }
  }
};
</script>
<style>
</style>