<template>
  <div class="w-100">
    <div class="d-flex w-100 justify-content-between align-items-center">
      <base-input v-if="data" :value="description" :inputText="placeholder" :errors="feedback" disabled></base-input>
      <base-input v-else :value="empty" :inputText="placeholder" :errors="feedback" disabled></base-input>
      <div class="mt-auto ml-2">
        <button-circle @click="showModal" name="edit" size="16" v-tooltip="'sélection et modification'"></button-circle>
      </div>
    </div>
    <div>
      <modal :id="name" :ref="name" :title="title" :subtitle="subtitle" :padding="false" :btns="false" :btn="null">
        <slot v-if="modalActive"></slot>
      </modal>
    </div>
  </div>
</template>
<script>
import Modal from "@/components/bases/Modal.vue";
import BaseInput from "@/components/bases/Input.vue";
import ButtonCircle from "@/components/bases/ButtonCircle.vue";

export default {
  name: "SelectModal",
  components: { Modal, BaseInput, ButtonCircle },
  data() {
    return {
      modalActive: false,
    };
  },
  props: {
    name: {
      Type: String,
      Required: false,
    },
    data: {
      Type: Object,
      Required: false,
      default: () => {
        return { description: "Sélection" };
      },
    },
    title: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    empty: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
      default: "Ajouter, modifier ou supprimer un élément",
    },
    btn: {
      type: String,
      required: false,
      default: "Ajouter",
    },
    padding: {
      type: Boolean,
      required: false,
      default: true,
    },
    btns: {
      type: Boolean,
      required: false,
      default: true,
    },
    feedback: {
      type: [Array, Object],
      required: false,
      default: function () {
        return { description: "" };
      },
    },
  },
  computed: {},
  methods: {
    showModal: function () {
      this.modalActive = true;
      this.$refs[this.name].show();
    },
    hideModal: function () {
      this.modalActive = false;
      this.$refs[this.name].hide();
    },
  },
};
</script>
<style></style>
