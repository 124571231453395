<template>
  <div class="wrapper">
    <div class="manager" v-if="!loadingGet">
      <div class="liste" v-show="!selected && !newCompte">
        <div class="pb-1">
          <div class="d-flex justify-content-between align-items-center pt-2">
            <h5 class="mb-0 ml-1 mr-1">Liste des comptes</h5>
            <button-circle name="plus" size="18" v-tooltip="'Ajouter un compte'" @click="clearForm"></button-circle>
          </div>
          <BaseInput type="search" v-model="search" inputText="Filtre" class modeIOS />
        </div>
        <div class="scroll-y rounded">
          <div v-if="!search">
            <div class="p-2 border-bottom cursor-pointer" v-for="c in comptes" :key="c.id">
              <div class="d-flex justify-content-between align-items-center">
                <div>{{ c.description }}</div>
                <div class="d-flex">
                  <button-circle
                    @click="showForm(c.id)"
                    name="edit"
                    size="16"
                    v-tooltip="'modifier le compte'"
                  ></button-circle>
                  <button-circle
                    @click="select(c)"
                    name="check"
                    size="16"
                    v-tooltip="'sélectionner le compte'"
                  ></button-circle>
                </div>
              </div>
            </div>
          </div>
          <div v-if="search">
            <div class="p-2 border-bottom cursor-pointer" v-for="c in filteredItems" :key="c.id">
              <div class="d-flex justify-content-between align-items-center">
                <div>{{ c.description }}</div>
                <div class="d-flex">
                  <button-circle
                    @click="showForm(c.id)"
                    name="edit"
                    size="16"
                    v-tooltip="'modifier le compte'"
                  ></button-circle>
                  <button-circle
                    @click="select(c)"
                    name="check"
                    size="16"
                    v-tooltip="'sélectionner le compte'"
                  ></button-circle>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form" v-show="selected || newCompte">
        <base-form backBtn @back="hideForm" @click="addOrUpdateCompte" :loadingSend="loadingSend">
          <base-form-group dense icon="briefcase">
            <base-form-row row>
              <base-input v-model="description" inputText="Description" :errors="feedback.description"></base-input>
            </base-form-row>
            <base-form-row row>
              <base-select
                inputText="Etat"
                v-model.number="compte_etat_id"
                :options="data.etats"
                :errors="feedback.compte_etat_id"
              ></base-select>
            </base-form-row>
            <base-form-row row>
              <base-select
                inputText="Secteur"
                v-model.number="compte_secteur_id"
                :options="data.secteurs"
                :errors="feedback.compte_secteur_id"
              ></base-select>
            </base-form-row>
            <base-form-row row>
              <base-input-area v-model="compte_notes" text="Notes"></base-input-area>
            </base-form-row>
          </base-form-group>
        </base-form>
      </div>
    </div>
    <loader-bloc class="m-5 scroll-y" v-if="loadingGet"></loader-bloc>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import { filterItems } from "@/helpers.js";
import ButtonCircle from "@/components/bases/ButtonCircle.vue";
import BaseInput from "@/components/bases/Input.vue";
import BaseSelect from "@/components/bases/Select.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormGroup from "@/components/bases/FormGroup.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import LoaderBloc from "@/components/bases/LoaderBloc.vue";

export default {
  components: {
    BaseInput,
    BaseSelect,
    BaseInputArea,
    BaseForm,
    BaseFormGroup,
    BaseFormRow,
    ButtonCircle,
    LoaderBloc,
  },
  props: {},
  data() {
    return {
      loadingGet: false,
      loadingSend: false,
      selected: false,
      newCompte: false,
      feedback: {},
      search: "",
    };
  },
  methods: {
    ...mapActions({
      getComptes: "comptes/getComptes",
      getData: "comptes/getData",
    }),
    select: function (compte) {
      this.$emit("select", compte);
    },
    showForm: function (id) {
      this.selected = true;
      this.$store.dispatch("comptes/getCompte", id);
    },
    hideForm: function () {
      this.selected = false;
      this.newCompte = false;
    },
    clearForm: function () {
      this.newCompte = true;
      this.$store.commit("comptes/setCompte", {});
    },
    addOrUpdateCompte: function () {
      if (this.newCompte) {
        this.loadingSend = true;
        this.$store
          .dispatch("comptes/create")
          .then((compte) => this.$emit("select", compte))
          .finally(() => (this.loadingSend = false));
      } else {
        this.loadingSend = true;
        this.$store
          .dispatch("comptes/update")
          .then((compte) => this.$emit("select", compte))
          .finally(() => (this.loadingSend = false));
      }
    },
  },
  computed: {
    ...mapGetters({
      comptes: "comptes/comptes",
      data: "comptes/data",
    }),
    ...mapFields("comptes", [
      "compte.description",
      "compte.compte_etat_id",
      "compte.compte_secteur_id",
      "compte.compte_notes",
    ]),
    filteredItems() {
      return filterItems(this.comptes, "description", this.search);
    },
  },
  mounted() {
    this.loadingGet = true;
    this.getData();
    this.getComptes().then(() => (this.loadingGet = false));
  },
};
</script>
<style lang="css" scoped>
.wrapper {
  min-height: 50vh;
}
.scroll-y {
  height: 60vh;
  overflow-y: auto;
}
.max-w-400 {
  max-width: 280px;
}
</style>
